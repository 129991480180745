import React from "react";
import "../css/terms.css";

export default function Terms() {
  return (
    <div className="termsBody">
      TERMS AND CONDITIONS
      <br />
      <br />
      These terms and conditions (the "Terms and Conditions") govern the use of
      kcbsoftware.com (the "Site"). This Site is owned and operated by KCB
      Software & Design. This Site is a portfolio.
      <br />
      <br />
      By using this Site, you indicate that you have read and understand these
      Terms and Conditions and agree to abide by them at all times.
      <br />
      <br />
      THESE TERMS AND CONDITIONS CONTAIN A DISPUTE RESOLUTION CLAUSE THAT
      IMPACTS YOUR RIGHTS ABOUT HOW TO RESOLVE DISPUTES. PLEASE READ IT
      CAREFULLY.
      <br />
      <br />
      Intellectual Property
      <br />
      All content published and made available on our Site is the property of
      KCB Software & Design and the Site's creators. This includes, but is not
      limited to images, text, logos, documents, downloadable files and anything
      that contributes to the composition of our Site.
      <br />
      <br />
      Acceptable Use
      <br />
      As a user of our Site, you agree to use our Site legally, not to use our
      Site for illegal purposes, and not to:
      <br />
      <br />
      Harass or mistreat other users of our Site;
      <br />
      Violate the intellectual property rights of the Site owners or any third
      party to the Site;
      <br />
      Hack into the account of another user of the Site;
      <br />
      Act in any way that could be considered fraudulent; or
      <br />
      Post any material that may be deemed inappropriate or offensive.
      <br />
      If we believe you are using our Site illegally or in a manner that
      violates these Terms and Conditions, we reserve the right to limit,
      suspend or terminate your access to our Site. We also reserve the right to
      take any legal steps necessary to prevent you from accessing our Site.
      <br />
      <br />
      Third Party Goods and Services
      <br />
      Our Site may offer goods and services from third parties. We cannot
      guarantee the quality or accuracy of goods and services made available by
      third parties on our Site.
      <br />
      <br />
      Limitation of Liability
      <br />
      KCB Software & Design and our directors, officers, agents, employees,
      subsidiaries, and affiliates will not be liable for any actions, claims,
      losses, damages, liabilities and expenses including legal fees from your
      use of the Site.
      <br />
      <br />
      Indemnity
      <br />
      Except where prohibited by law, by using this Site you indemnify and hold
      harmless KCB Software & Design and our directors, officers, agents,
      employees, subsidiaries, and affiliates from any actions, claims, losses,
      damages, liabilities and expenses including legal fees arising out of your
      use of our Site or your violation of these Terms and Conditions.
      <br />
      <br />
      Applicable Law
      <br />
      These Terms and Conditions are governed by the laws of the Province of
      British Columbia.
      <br />
      <br />
      Dispute Resolution
      <br />
      Subject to any exceptions specified in these Terms and Conditions, if you
      and KCB Software & Design are unable to resolve any dispute through
      informal discussion, then you and KCB Software & Design agree to submit
      the issue first before a non-binding mediator and to an arbitrator in the
      event that mediation fails. The decision of the arbitrator will be final
      and binding. Any mediator or arbitrator must be a neutral party acceptable
      to both you and KCB Software & Design.
      <br />
      <br />
      Notwithstanding any other provision in these Terms and Conditions, you and
      KCB Software & Design agree that you both retain the right to bring an
      action in small claims court and to bring an action for injunctive relief
      or intellectual property infringement.
      <br />
      <br />
      Severability
      <br />
      If at any time any of the provisions set forth in these Terms and
      Conditions are found to be inconsistent or invalid under applicable laws,
      those provisions will be deemed void and will be removed from these Terms
      and Conditions. All other provisions will not be affected by the removal
      and the rest of these Terms and Conditions will still be considered valid.
      <br />
      <br />
      Changes
      <br />
      These Terms and Conditions may be amended from time to time in order to
      maintain compliance with the law and to reflect any changes to the way we
      operate our Site and the way we expect users to behave on our Site. We
      will notify users by email of changes to these Terms and Conditions or
      post a notice on our Site.
      <br />
      <br />
      Contact Details
      <br />
      Please contact us if you have any questions or concerns. Our contact
      details are as follows:
      <br />
      <br />
      (587) 888-3825
      <br />
      katherine@kcbsoftware.com
      <br />
      1333 Haro Street, BC, V6E1G4
      <br />
      <br />
      You can also contact us through the feedback form available on our Site.
      <br />
      <br />
      Effective Date: 18th day of March, 2022
      <br />
      <br />
      ©2002-2022 LawDepot.ca®
      <br />
      <br />
    </div>
  );
}
