import React from "react";
import "../css/terms.css";

export default function PrivacyPolicy() {
  return (
    <body>
      <p className="termsBody">
        kcbsoftware.com Privacy Policy
        <br />
        <br />
        Type of website: Portfolio
        <br />
        <br />
        Effective date: 16th day of March, 2022
        <br />
        <br />
        kcbsoftware.com (the "Site") is owned and operated by KCB Software &
        Design. KCB Software & Design is the data controller and can be
        contacted at:
        <br />
        <br />
        katherine@kcbsoftware.com
        <br />
        (587) 888-3525
        <br />
        1333 Haro St, Vancouver, V6E1G4
        <br />
        <br />
        Purpose
        <br />
        The purpose of this privacy policy (this "Privacy Policy") is to inform
        users of our Site of the following:
        <br />
        <br />
        The personal data we will collect;
        <br />
        Use of collected data;
        <br />
        Who has access to the data collected;
        <br />
        The rights of Site users; and
        <br />
        The Site's cookie policy.
        <br />
        This Privacy Policy applies in addition to the terms and conditions of
        our Site.
        <br />
        <br />
        GDPR
        <br />
        For users in the European Union, we adhere to the Regulation (EU)
        2016/679 of the European Parliament and of the Council of 27 April 2016,
        known as the General Data Protection Regulation (the "GDPR"). For users
        in the United Kingdom, we adhere to the GDPR as enshrined in the Data
        Protection Act 2018.
        <br />
        <br />
        We have not appointed a Data Protection Officer as we do not fall within
        the categories of controllers and processors required to appoint a Data
        Protection Officer under Article 37 of the GDPR.
        <br />
        <br />
        Consent
        <br />
        By using our Site users agree that they consent to:
        <br />
        <br />
        The conditions set out in this Privacy Policy.
        <br />
        When the legal basis for us processing your personal data is that you
        have provided your consent to that processing, you may withdraw your
        consent at any time. If you withdraw your consent, it will not make
        processing which we completed before you withdrew your consent unlawful.
        <br />
        <br />
        You can withdraw your consent by: Email us at katherine@kcbsoftware.com.
        <br />
        <br />
        Legal Basis for Processing
        <br />
        We collect and process personal data about users in the EU only when we
        have a legal basis for doing so under Article 6 of the GDPR.
        <br />
        <br />
        We rely on the following legal bases to collect and process the personal
        data of users in the EU:
        <br />
        <br />
        Users have provided their consent to the processing of their data for
        one or more specific purposes; and
        <br />
        Processing of user personal data is necessary to a task carried out in
        the public interest or in the exercise of our official authority.
        <br />
        <br />
        Personal Data We Collect
        <br />
        We only collect data that helps us achieve the purpose set out in this
        Privacy Policy. We will not collect any additional data beyond the data
        listed below without notifying you first.
        <br />
        <br />
        Data Collected Automatically
        <br />
        When you visit and use our Site, we may automatically collect and store
        the following information:
        <br />
        <br />
        IP address; and
        <br />
        Location.
        <br />
        Data Collected in a Non-Automatic Way
        <br />
        We may also collect the following data when you perform certain
        functions on our Site:
        <br />
        <br />
        First and last name;
        <br />
        Email address;
        <br />
        Phone number;
        <br />
        Address; and
        <br />
        Payment information.
        <br />
        This data may be collected using the following methods:
        <br />
        <br />
        Email Form; and
        <br />
        Credit Card Payment Request Form.
        <br />
        How We Use Personal Data
        <br />
        Data collected on our Site will only be used for the purposes specified
        in this Privacy Policy or indicated on the relevant pages of our Site.
        We will not use your data beyond what we disclose in this Privacy
        Policy.
        <br />
        <br />
        The data we collect automatically is used for the following purposes:
        <br />
        <br />
        To Identify who is coming on the site and from where.
        <br />
        The data we collect when the user performs certain functions may be used
        for the following purposes:
        <br />
        <br />
        Communication; and
        <br />
        Payment for specific customers who are coming for that specific purpose
        on that area of the website..
        <br />
        Who We Share Personal Data With
        <br />
        Employees
        <br />
        We may disclose user data to any member of our organization who
        reasonably needs access to user data to achieve the purposes set out in
        this Privacy Policy.
        <br />
        <br />
        Other Disclosures
        <br />
        We will not sell or share your data with other third parties, except in
        the following cases:
        <br />
        <br />
        If the law requires it;
        <br />
        If it is required for any legal proceeding;
        <br />
        To prove or protect our legal rights; and
        <br />
        To buyers or potential buyers of this company in the event that we seek
        to sell the company.
        <br />
        If you follow hyperlinks from our Site to another Site, please note that
        we are not responsible for and have no control over their privacy
        policies and practices.
        <br />
        <br />
        How Long We Store Personal Data
        <br />
        User data will be stored until the purpose the data was collected for
        has been achieved.
        <br />
        <br />
        You will be notified if your data is kept for longer than this period.
        <br />
        <br />
        How We Protect Your Personal Data
        <br />
        In order to protect your security, we only use the data through emailing
        and communication and is only accessible to our employees who have
        access to the email.
        <br />
        <br />
        While we take all reasonable precautions to ensure that user data is
        secure and that users are protected, there always remains the risk of
        harm. The Internet as a whole can be insecure at times and therefore we
        are unable to guarantee the security of user data beyond what is
        reasonably practical.
        <br />
        <br />
        Your Rights as a User
        <br />
        Under the GDPR, you have the following rights:
        <br />
        <br />
        Right to be informed;
        <br />
        Right of access;
        <br />
        Right to rectification;
        <br />
        Right to erasure;
        <br />
        Right to restrict processing;
        <br />
        Right to data portability; and
        <br />
        Right to object.
        <br />
        Children
        <br />
        We do not knowingly collect or use personal data from children under 16
        years of age. If we learn that we have collected personal data from a
        child under 16 years of age, the personal data will be deleted as soon
        as possible. If a child under 16 years of age has provided us with
        personal data their parent or guardian may contact our privacy officer.
        <br />
        <br />
        How to Access, Modify, Delete, or Challenge the Data Collected
        <br />
        If you would like to know if we have collected your personal data, how
        we have used your personal data, if we have disclosed your personal data
        and to who we disclosed your personal data, if you would like your data
        to be deleted or modified in any way, or if you would like to exercise
        any of your other rights, please contact us at :
        katherine@kcbsoftware.com
        <br />
        <br />
        Do Not Track Notice
        <br />
        Do Not Track ("DNT") is a privacy preference that you can set in certain
        web browsers. We do not track the users of our Site over time and across
        third party websites and therefore do not respond to browser-initiated
        DNT signals.
        <br />
        <br />
        Cookie Policy
        <br />
        A cookie is a small file, stored on a user's hard drive by a website.
        Its purpose is to collect data relating to the user's browsing habits.
        You can choose to be notified each time a cookie is transmitted. You can
        also choose to disable cookies entirely in your internet browser, but
        this may decrease the quality of your user experience.
        <br />
        <br />
        We use the following types of cookies on our Site:
        <br />
        <br />
        Functional cookies
        <br />
        Functional cookies are used to remember the selections you make on our
        Site so that your selections are saved for your next visits;
        <br />
        <br />
        Analytical cookies
        <br />
        Analytical cookies allow us to improve the design and functionality of
        our Site by collecting data on how you access our Site, for example data
        on the content you access, how long you stay on our Site, etc; and
        <br />
        <br />
        Third-Party Cookies
        <br />
        Third-party cookies are created by a website other than ours. We may use
        third-party cookies to achieve the following purposes:
        <br />
        Google Analytics.
        <br />
        Modifications
        <br />
        This Privacy Policy may be amended from time to time in order to
        maintain compliance with the law and to reflect any changes to our data
        collection process. When we amend this Privacy Policy we will update the
        "Effective Date" at the top of this Privacy Policy. We recommend that
        our users periodically review our Privacy Policy to ensure that they are
        notified of any updates. If necessary, we may notify users by email of
        changes to this Privacy Policy.
        <br />
        <br />
        Complaints
        <br />
        If you have any complaints about how we process your personal data,
        please contact us through the contact methods listed in the Contact
        Information section so that we can, where possible, resolve the issue.
        If you feel we have not addressed your concern in a satisfactory manner
        you may contact a supervisory authority. You also have the right to
        directly make a complaint to a supervisory authority. You can lodge a
        complaint with us by contacting us at katherine@kcbsoftware.com. <br />
        <br />
        Contact Information
        <br />
        Email: katherine@kcbsoftware.com
        <br />
        Call or Text: 587-888-3825
        <br />
        <br />
        <br />
        ©2002-2022 LawDepot.ca®
      </p>
    </body>
  );
}
