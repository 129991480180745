import React from "react";
import { FaArrowDown, FaCheck } from "react-icons/fa";
import { ReactTyped } from "react-typed";
import { HashLink as Link } from "react-router-hash-link";
import "../css/landingpage.css";
import "../css/pages.css";
import "../css/web.css";
import SEOHead from "../components/SEOHead";

export default function SEO() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div style={{ overflowX: "hidden" }}>
      <SEOHead
        title="SEO services"
        description="SEO services and why SEO is important. "
        name="KCB Software"
        type="service"
      />
      <img
        className="backgroundImageWWA"
        style={{ filter: "brightness(90%)" }}
        alt="seo, kcbsoftware, seo company, why is seo important"
        src="https://res.cloudinary.com/kcb-software-design/image/upload/v1730938287/wooden-word-seo-and-magnifying-glass-on-a-black-ac-2024-03-02-04-15-58-utc_dxw1m3.jpg"
      />
      <div className="line-navbar"></div>
      <div
        className="margin"
        style={{
          marginTop: "-10px",
          backgroundColor: "#40b58a",
        }}
      >
        <div className="row seo-b1" style={{ justifyContent: "space-around" }}>
          <div className="column">
            <ReactTyped
              strings={["Why focus on SEO?"]}
              typeSpeed={80}
              className="secondaryWWA seob1-typed"
              style={{ textAlign: "left", color: "black", fontWeight: "bold" }}
            ></ReactTyped>
            <br />
            <div style={{ marginTop: "30px", lineHeight: "3" }}>
              <FaCheck style={{ color: "white" }} />{" "}
              <span className="regText">Online Visability</span>
              <br />
              <FaCheck style={{ color: "white" }} />{" "}
              <span className="regText">Builds Trust & Credibility</span>
              <br />
              <FaCheck style={{ color: "white" }} />{" "}
              <span className="regText">Increased Engagement</span>
              <br />
              <FaCheck style={{ color: "white" }} />{" "}
              <span className="regText">Increased Sales</span>
              <br />
              <FaCheck style={{ color: "white" }} />{" "}
              <span className="regText">Better User Experience</span>
              <br />
            </div>
          </div>
          <div>
            <img
              className="web-dev-graphic float rocketman"
              src="https://res.cloudinary.com/kcb-software-design/image/upload/v1719797536/KCB/Cute_Astronaut_Riding_Rocket_With_Peace_Hand_Converted_rxqzoa.png"
              alt="rocketman, seo, search engine optimization, kcb software, business "
            />
          </div>
        </div>
      </div>
      <div className="blue margin seotext">
        {/* <ReactTyped
          strings={["Why SEO is important..."]}
          typeSpeed={80}
          className="secondaryWWA"
          style={{ color: "#40b58a" }}
        ></ReactTyped> */}
        <br />
        <div
          className="row"
          style={{ justifyContent: "space-between", flexWrap: "wrap" }}
        >
          <div className="introductionPara seotext-para">
            <b>
              Utilizing SEO is crucial for boosting sales and enhancing exposure
            </b>
            &nbsp; as it helps forge connections between businesses and
            potential customers actively seeking products or services online.
            When a website secures a ranking in search engine results it gains
            increased visibility to users who are already keenly interested, in
            the business offerings thereby boosting the chances of conversions.
            The better a website ranks for keywords the greater its potential to
            draw in qualified traffic. This focused traffic has a chance of
            turning into purchases since these individuals are typically, at a
            stage where they are ready to make a decision in their purchasing
            journey and seeking solutions that match your business offerings.
            <br />
            <br />
            In addition to that search engine optimization (SEO) is
            significantly important in establishing the visibility and
            reputation of a brand. Factors for sustained sales expansion.
            Consumers tend to place trust in and interact with brands that show
            up prominently in search results as top rankings are typically
            linked with credibility and trustworthiness. Persistent SEO
            strategies, like refining website content boosting performance and
            acquiring backlinks not just boost search visibility but also
            elevate user experience which ultimately results in improved
            customer contentment and loyalty. Investment in SEO enables
            businesses to maintain visibility among their target audience and
            draw in leads that lead to a boost, in sales and revenue in the long
            run.
          </div>
          <div>
            <img
              className=" seoSecondPic"
              src="https://res.cloudinary.com/kcb-software-design/image/upload/v1730939941/seo-optimization-analysis-tools-entrepreneurs-in-2024-06-26-19-19-28-utc_yr8kb8.jpg"
            />
          </div>
        </div>
      </div>
      <div className="line-navbar"></div>
      <div style={{ backgroundColor: "#40b58a", padding: "50px 0px 0px 0px" }}>
        <div className="wwo-container">
          <div style={{ textAlign: "center" }}>
            <ReactTyped
              strings={["What we offer"]}
              typeSpeed={80}
              className="secondaryWWA"
              style={{
                color: "black",
                fontWeight: "bold",
              }}
            ></ReactTyped>
          </div>
          <div className="introductionPara wwo-seo">
            Here at KCB we offer a couple of different SEO services to get your
            business a higher ranking on google and increase your visibility to
            potential customers.
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <FaArrowDown className="downarrow" />
            <FaArrowDown className="downarrow" />
            <FaArrowDown className="downarrow" />
          </div>
        </div>
        <div
          className="image-con1"
          style={{
            backgroundImage:
              "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(https://res.cloudinary.com/kcb-software-design/image/upload/v1730950774/bearded-developer-using-laptop-on-blue-with-websi-2024-11-01-03-38-07-utc_ycdpxf.jpg)",
          }}
        >
          <ReactTyped
            strings={["Paid organic traffic (KLIMB SEO)"]}
            typeSpeed={80}
            className="secondaryWWA"
            style={{ color: "#40b58a", fontSize: "25px", fontWeight: "bold" }}
          ></ReactTyped>
          <div
            className="introductionPara"
            style={{ minWidth: "250px", paddingBottom: "25px" }}
          >
            Paid organic traffic has been proven to build credibility and boost
            rating on googles search engine. When Google observes an influx of
            visitors to your website searching for keywords in particular
            categories it interprets this as a sign that your site is pertinent
            to those keywords. Increased traffic signifies that users find the
            content, on your site valuable which enhances your expertise on the
            subject and increases your ranking. <br />
            <br />
            When there is an increase in website traffic to your content on
            Googles platform there is an amount of data available for Google to
            assess how well your content is performing. Having steady traffic
            coming from different localized locations that could benifit from
            your business , devices and demographics indicates that your content
            is valuable, to a wide range of people, which is one of the aspects
            that Google takes into account when determining search engine
            ranking.
            <br />
            <br />
            We at KCB have perfected this technique to give you organic traffic
            through your preferred keyword searches so you can rank higher on
            google and start getting your visibility out to customers at an
            accelerated rate!
          </div>
        </div>
        <div
          className="line-navbar"
          style={{ backgroundColor: "#40b58a" }}
        ></div>

        <div
          className="image-con1"
          style={{
            backgroundImage:
              "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(https://res.cloudinary.com/kcb-software-design/image/upload/v1730954570/cropped-shot-of-business-person-using-laptop-with-2024-11-01-02-55-58-utc_oma4t5.jpg)",
          }}
        >
          <ReactTyped
            strings={["On website SEO services"]}
            typeSpeed={80}
            className="secondaryWWA "
            style={{ color: "#40b58a", fontSize: "25px" }}
          ></ReactTyped>
          <div
            className="introductionPara"
            style={{ minWidth: "250px", paddingBottom: "25px" }}
          >
            Improving your website with on site SEO services involves optimizing
            its design and content to boost search engine rankings and improve
            user satisfaction levels. It usually begins with audit of the
            website to pinpoint any areas that need improvement such as keyword
            usage optimization, page loading speed enhancement, mobile
            responsiveness improvement and URL structuring. After identifying
            these areas for improvement we will optimize sections by
            incorporating keywords in important elements, like titles, meta
            descriptions, headings and body text. In addition to that they
            tackle details such, as improving visuals and adding links to assist
            search engines in navigating the website and indexing it efficiently
            resulting in a unified and search friendly site that meets Googles
            ranking criteria effectively. <br />
            <br />
            We also focus on improving user experience. Importantly, focusing on
            user interfaces and fast loading speeds. These enhancements are
            designed to lower bounce rates for websites while boosting dwell
            times and increasing user engagement. We will make sure that the
            content and layout of your website adhere to the SEO practices to
            enhance your sites visibility and credibility gradually through, on
            page SEO services This could result in improved search rankings and
            increased organic traffic over time.
            <br />
            <br />
            if you are looking for a website made, we also offer{" "}
            <Link
              to="/services/webdev"
              style={{ color: "#40b58a" }}
              onClick={scrollToTop}
            >
              web development services
            </Link>
          </div>
        </div>
      </div>
      <div className="yellow margin">
        <ReactTyped
          strings={["Want to know more?"]}
          typeSpeed={80}
          className="secondaryWWA"
          style={{ textAlign: "center", color: "black" }}
        ></ReactTyped>
        <div className="b1" style={{ textAlign: "left", color: "#0f2a23" }}>
          Contact us via our contact form to learn more about what web
          development solution is best for you and your business.
        </div>
        <br />
        <br />
        <br />
        <Link
          onClick={scrollToTop}
          to="/contact"
          className="button-primary"
          style={{ textDecoration: "none" }}
        >
          {" "}
          Contact Us
        </Link>
      </div>
    </div>
  );
}
