import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import logo from "./logo.svg";
import { HelmetProvider } from "react-helmet-async";
import LandingPage from "./Pages/LandingPage";
import WhoWeAre from "./Pages/WhoWeAre.js";
import AppDev from "./Pages/AppDev.js";
import Web from "./Pages/Web.js";
import Bots from "./Pages/Bots.js";
import Contact from "./Pages/Contact.js";
import Automation from "./Pages/Automation.js";
import SEO from "./Pages/SEO.js";
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer.js";
import PageNotFound from "./Pages/PageNotFound";
import Terms from "./Pages/term";
import PrivacyPolicy from "./Pages/privacyPolicy";
const helmetContext = {};

function App() {
  return (
    <HelmetProvider context={helmetContext}>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/whoweare" element={<WhoWeAre />} />
          <Route path="/services/automation" element={<Automation />} />
          <Route path="/services/bots" element={<Bots />} />
          <Route path="/services/webdev" element={<Web />} />
          <Route path="/services/appdev" element={<AppDev />} />
          <Route path="/services/seo" element={<SEO />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/termsandconditions" element={<Terms />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </Router>
    </HelmetProvider>
  );
}

export default App;
