import React, { useState, useEffect } from "react";
import { ReactTyped } from "react-typed";
import LazyLoad from "react-lazyload";
import Aos from "aos";
import "aos/dist/aos.css";
import "../css/landingpage.css";
import "../css/pages.css";
import SEOHead from "../components/SEOHead";

export default function WhoWeAre() {
  useEffect(() => {
    Aos.init({ duration: 1200 });
  }, []);
  return (
    <div style={{ overflowX: "hidden" }}>
      <SEOHead
        title="Who we are / About Us"
        description="About us as a software development company, what our values are, and what differentiates us from our competitors. "
        name="KCB Software"
        type="article"
      />
      <div className="tabletContainer ">
        <LazyLoad>
          <img
            className="backgroundImageWWA"
            src="https://res.cloudinary.com/kcb-software-design/image/upload/v1714188313/programming-equipment-in-dark-2023-11-27-05-28-19-utc_jhsyis.jpg"
          />
        </LazyLoad>
        <ReactTyped
          strings={["Who We Are.."]}
          typeSpeed={80}
          className="tablet-title"
        ></ReactTyped>
      </div>

      <div className="line-navbar"></div>
      <div className="block2" style={{ paddingLeft: "30px" }}>
        <div className="WWAsecondary-container">
          <ReactTyped
            strings={["Software is our passion..."]}
            typeSpeed={80}
            className="secondaryWWA"
          ></ReactTyped>
          <div className="introductionPara" data-aos="fade-up">
            We started KCB Software & Design in 2020 with a vision to design and
            develop high quality software and create long lasting relationships
            with our clients. We heard of the horror stories people had with
            software companies and developers and we wanted to bridge that gap
            to create a safe enviroment for companies to build their businesses.
            We have been known as the software company people turn to when they
            have had a bad experience in the industry. As experts in problem
            solving, we clean up any mess thrown at us, no matter the size.
            <br />
            <br />
            Software doesn't have to be a complicated process for the client. We
            pride ourselves on the comfort our clients have, knowing that their
            software is in reliable hands. It can be as easy as a couple
            questions to get an idea about your business, and then you leave the
            rest up to us.
            <br />
            <br />
            With individuals within our company with years of experience in
            software under their belt, who are driven to innovate and create
            modern solutions in the industry; our code, database design, backend
            design and frontend design, is always top tier. There has been no
            problem we havent been able to solve.
          </div>
        </div>
        <div style={{ position: "relative", alignItems: "center" }}>
          <LazyLoad>
            <img
              data-aos="fade-down"
              class="tablet-horizontal"
              src="https://res.cloudinary.com/kcb-software-design/image/upload/v1714092762/KCB-tablet-horizontal_ukrl43.png"
            />
            <img
              class="tablet-vertical"
              src="https://res.cloudinary.com/kcb-software-design/image/upload/v1714092764/Top_Cam_-_Horizontal_ttdqfy.png"
            />
          </LazyLoad>
        </div>
      </div>
      <div className="line-navbar"></div>
      <div className="block1" style={{ paddingBottom: "50px" }}>
        <div class="valuesBlock">
          <ReactTyped
            strings={["Our Values.."]}
            typeSpeed={80}
            className="valuesTitleWWA"
          ></ReactTyped>

          <img
            data-aos="fade-in"
            className="webOfValuesImg"
            src="https://res.cloudinary.com/kcb-software-design/image/upload/v1714196414/KCB_WEB_OF_VALUES_hkkrb8.png"
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="valueContainer" data-aos="fade-in">
              <LazyLoad>
                <img
                  data-aos="flip-left"
                  className="iconsWWA"
                  src="https://res.cloudinary.com/kcb-software-design/image/upload/v1714200291/Asset_6_vfvnvb.png"
                />
              </LazyLoad>
              <ReactTyped
                strings={["Integrity.."]}
                typeSpeed={80}
                className="valuesTitleWWA integrity"
                style={{ color: "#0078C1" }}
              ></ReactTyped>

              <div className="introductionPara" style={{ textAlign: "center" }}>
                Integrity serves as the compass guiding every aspect of our
                operations here at KCB. Integrity is important to us, for
                building trust with our clients and partners. Upholding
                integrity through action creates an environment of honesty,
                transparency and accountability. In the case of a software
                company personal integrity holds importance given the complex
                nature of the tasks, at hand. Software development often
                requires collaboration, problem-solving, and decision-making,
                all of which can be influenced by individual ethics. Moreover,
                personal integrity breeds a culture of accountability and
                responsibility, where actions align with words, and commitments
                are honored. Moreover, integrity guides decision-making
                processes within every aspect of our company, ensuring that
                ethical considerations are prioritized at every level. We pride
                ourselves on our high level of integrity.
              </div>
            </div>
          </div>

          <div className="valueContainer" data-aos="fade-in">
            <LazyLoad>
              <img
                className="iconsWWA"
                data-aos="flip-right"
                src="https://res.cloudinary.com/kcb-software-design/image/upload/v1714200291/Asset_4_zyxove.png"
              />
            </LazyLoad>
            <ReactTyped
              strings={["Customer Experience.."]}
              typeSpeed={80}
              className="valuesTitleWWA integrity"
              style={{ color: "#00A1E4" }}
            ></ReactTyped>

            <div className="introductionPara" style={{ textAlign: "center" }}>
              At the core of our business lies customer experience. An element
              that fosters enduring connections and customer loyalty to our
              brand, in a fast paced modern world filled with choices and short
              attention spans.
              <br />
              <br />
              For our software company, prioritizing customer experience is
              paramount for several reasons. Firstly, it directly impacts
              customer satisfaction and retention. When users have positive
              interactions with our software, they're more likely to continue
              using it, recommend it, and even advocate for it. We use our
              detailed knowledge to make sure your customers have an emotional
              connection to your brand, are not lost, and feel as ease when
              navigating through the software. Creating loyal and repeat
              customers is an absolute must, and doing so has to be done with
              the first impressions of the program itself.
              <br />
              <br />
              When is comes to our own customer experience ( our clients ) , it
              goes beyond merely delivering a product; it encompasses the entire
              journey from initial contact. We are determined to make every
              client, a happy one. With feedback , communication, and diligence
              ( on our end ) to make sure every client knows that their
              businesses are in great hands, we make a safe and enjoyable
              experience for every business that decides to include us in their
              software development. Customer experience and satisfaction is
              everything to us.
            </div>
          </div>
          <div className="valueContainer" data-aos="fade-in">
            <LazyLoad>
              <img
                data-aos="flip-left"
                className="iconsWWA"
                src="https://res.cloudinary.com/kcb-software-design/image/upload/v1714200290/Asset_5_j4boz1.png"
              />
            </LazyLoad>
            <ReactTyped
              strings={["Excellence.."]}
              typeSpeed={80}
              className="valuesTitleWWA integrity"
              style={{ color: "#64BB6F" }}
            ></ReactTyped>

            <div className="introductionPara" style={{ textAlign: "center" }}>
              At our software companys core is the commitment to excellence as
              our guiding light and ultimate objective in all facets of our
              business operations. In todays paced tech environment
              characterized by intense competition and ever increasing user
              demands striving for excellence isn't just a nice to have; it's a
              critical imperative, for thriving and prospering.
              <br />
              <br />
              Achieving excellence in our products involves providing solutions
              that go beyond meeting customer needs and expectations. It
              requires attention to detail and creative problem solving while
              maintaining a strong focus on quality standards in our software
              development processes to create dependable and user friendly
              products that build trust and loyalty, with our customers.
              <br />
              <br />
              Furthermore our commitment to excellence is evident not in our
              products but also in all aspects of our companys operations
              especially in customer support. Creating an environment that
              values excellence motivates our staff to constantly enhance and
              come up with ideas, which leads to the growth and flexibility of
              the organization. Ultimately excellence is not merely a target to
              reach ; it is an attitude that propels our aspirations powers our
              achievements. Distinguishes us as a frontrunner, in the
              competitive software sector.
            </div>
          </div>
          <div className="valueContainer" data-aos="fade-in">
            <LazyLoad>
              <img
                data-aos="flip-right"
                className="iconsWWA"
                src="https://res.cloudinary.com/kcb-software-design/image/upload/v1714200291/Asset_2_jlmlqq.png"
              />
            </LazyLoad>
            <ReactTyped
              strings={["Creativity and  Inovation.."]}
              typeSpeed={80}
              className="valuesTitleWWA integrity"
              style={{ color: "#8DC63F" }}
            ></ReactTyped>

            <div className="introductionPara" style={{ textAlign: "center" }}>
              In our software companys DNA lies a sense of creativity and
              innovation that drives us ahead in a fast paced and competitive
              field. In todays evolving tech landscape being able to think
              outside the box is not just beneficial—it's essential for staying
              ahead and standing out in the crowd. Creativity sparks ideas and
              unique approaches, to tough challenges empowering us to question
              norms push limits and venture into unexplored territories leading
              to revolutionary breakthroughs and game changing innovations.
              <br />
              <br />
              In addition to this point; it's important that we tap into our
              side and think outside the box to keep up with what our customers
              want and expect from us as a business. Innovation plays a role in
              this process. By regularly coming up with new ideas and improving
              our products or services we not only meet but go beyond customer
              needs which helps build trust and boost company growth. When we
              make creativity and innovation central to our values we establish
              ourselves as pioneers, in the field guiding change. Shaping the
              way forward in software development..
            </div>
          </div>
        </div>
      </div>
      <div className="line-navbar"></div>
      <div className="block2 blockKatherine">
        <div className="founder-container">
          <ReactTyped
            strings={["Words from our Founder"]}
            className="secondaryWWA founderTyped"
          ></ReactTyped>
          <div className="introductionPara aboutKatherine" data-aos="fade-in">
            My name is Katherine, I started this company to redefine what
            software is for businesses. I saw a lot of toxicity and
            unreliability in the software development world, and I wanted to
            bridge that gap, while creating outstanding software. I wanted to
            incorporate client appreciation, dedication, and reliability into
            every aspect of operations. I am proud to say we have achieved that.
            Every project we create, work on, or improve, we give our undivided
            attention to.
            <br />
            <br />I am a software engineer, I was in the gaming industry, then I
            decided to leave to follow my passion in web and mobile apps. I am a
            full stack programmer in many different coding languages, and thrive
            in problem solving and learning new skills to increase my skill set.
            I have such a passion for software, it runs through my veins.
            Whether its complex algorithms, database design, API’s, front end
            design, project design, software design, UX and UI design, backend
            functionality, servers, optimization, automation, or anything else;
            I give it my all.
            <br />
            <br />I decided to create a healthy, friendly, flexible software
            company where our clients and employees are proud to be apart of our
            atmosphere. Where ideas on all ends are heard and the words “I
            can’t” or “impossible” don’t exist. I wanted to create excellence in
            every sense of the word. This is software we are talking about, the
            possibilities are endless. I am so proud of what we have created as
            a collective, and for the growth to come.
            <br />
            <br />
            Thank you so much for stumbling upon our website!
          </div>
        </div>
        <div className="katherineImageContainer">
          <LazyLoad>
            <img
              data-aos="flip-left"
              className="katherine-photo"
              alt="katherine brough, founder,kcbsoftware, kcb software & design, software engineer"
              src="https://res.cloudinary.com/kcb-software-design/image/upload/v1714258588/KatherineLaugh_zmixzf.png"
            />
          </LazyLoad>
        </div>
      </div>
    </div>
  );
}
