import React from "react";
import { ReactTyped } from "react-typed";
import { HashLink as Link } from "react-router-hash-link";
export default function Automation() {
  return (
    <div>
      <div className="tabletContainer ">
        <img
          className="backgroundImageWWA"
          src="https://res.cloudinary.com/kcb-software-design/image/upload/v1713929761/vintage-robot-tin-toy-on-yello-background-2023-11-27-05-05-22-utc_map0dz.jpg"
        />
        <ReactTyped
          strings={["Automation / <br/>Web Scraping"]}
          typeSpeed={80}
          className="tablet-title center"
        ></ReactTyped>
      </div>
      <div className="margin lightblue">
        <h2 className="construction-title">
          This page is currently under construction
        </h2>
        <div className="construction-text">
          We do offer this service but this page is being redone. <br />
          Please contact us for more information.
        </div>
      </div>
      <div className="yellow margin">
        <ReactTyped
          strings={["Want to know more?"]}
          typeSpeed={80}
          className="secondaryWWA"
          style={{ textAlign: "center", color: "black" }}
        ></ReactTyped>
        <div className="b1" style={{ textAlign: "left", color: "#0f2a23" }}>
          Contact us via our contact form to learn more about what web
          development solution is best for you and your business.
        </div>
        <br />
        <br />
        <br />
        <Link
          to="/contact"
          className="button-primary"
          style={{ textDecoration: "none" }}
        >
          {" "}
          Contact Us
        </Link>
      </div>
    </div>
  );
}
